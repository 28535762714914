<template>
    <div>
        <section class="top-title">
			<div class="top-page">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div class="top-page-heading">
								<h1>FLEET</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="breadcrumbs">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<ul>
								<li>
									<router-link to="/">Home </router-link>
								</li>
								<li>
									/ Fleet
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Top Title -->
		<!-- Start Our Fleet Area -->
		<section class="our-fleet-area fix-loadmore">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="template-title center">
							<h1>Our Fleet</h1>
							<span>Our Fleet</span>
							<p>We also take custom orders and will help you acquire a specific yacht.</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6" 
						v-for="(item, index) in fleets"
						:key="index"
						@click="$router.push({name: 'booking'})">
						<div class="select-car al-center">
							<div class="select-box">
								<div class="image-car">
									<VueSlickCarousel v-bind="settings">
										<img v-for="(img, index) in item.carImage"
											:key="index"
											:src="img.imageUrl" alt="">
									</VueSlickCarousel>
								</div>
								<div class="box-text">
									<div class="top">
										<h3>{{item.categoryId.categoryName}}</h3>
										<div class="name-car">
											{{item.carType}}
										</div>
									</div>
									<div class="content">
										<ul>
											<li><img src="/images/booking/people.png" alt=""> Max . {{item.categoryId.maxPersons}}</li>
											<li><img src="/images/booking/vali.png" alt=""> Max . {{item.categoryId.maxBags}}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
</template>

<script>
import { request } from "../utils/api";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
	components: { VueSlickCarousel },
    data(){
        return {
            fleets: [],
			settings: {
				"autoplay": true,
				"edgeFriction": 0.35,
				"fade": true,
  				"infinite": true,
				"speed": 500,
				"slidesToShow": 1,
				"cssEase": "linear",
				"slidesToScroll": 1
			}
        }
    },
    methods:{
        async loadFleets(){
            const resp = await request({
                method: "get",
                url: `/api/v1/cars/getfleets`,
            });

            if (resp.remote === "success") {
                this.fleets = resp.data;
            }else{
                this.error = resp.errors
            }
        },
    },
    mounted(){
        this.loadFleets()
    }
}
</script>

<style>

</style>